import ComplexProductCard, {
  ComplexProductCardProps,
} from '@tvg/fuse-complex-product-card';
import {
  useCriteoLoadEvent,
  useCriteoViewEvent,
} from 'client/hooks/criteoEvents';
import { getProductProviderProps } from 'client/utils/providerProps';
import { decodeHTML } from 'entities';
import type { ReactElement } from 'react';
import React, { useRef } from 'react';
import {
  CIORecProduct,
  CriteoRecProduct,
  RecProduct,
} from 'server/services/recommendation';
import { RecProvider } from 'shared/consts/recommendations';

export interface InGridProductCardProps<T extends RecProvider = RecProvider>
  extends ComplexProductCardProps {
  provider: T;
  product: RecProduct &
    (T extends RecProvider.Constructor
      ? CIORecProduct
      : T extends RecProvider.Criteo
      ? CriteoRecProduct
      : RecProduct);
  onLoadBeacon?: string;
}

function InGridProductCard<T extends RecProvider>({
  provider,
  product,
  onLoadBeacon,
  ...props
}: InGridProductCardProps<T>): ReactElement {
  const container = useRef<HTMLDivElement | null>(null);
  if (onLoadBeacon) {
    useCriteoLoadEvent(onLoadBeacon);
  }
  useCriteoLoadEvent(product.onLoadBeacon);
  useCriteoViewEvent(container);

  return (
    <div ref={container}>
      <ComplexProductCard
        brand={decodeHTML(product.brand ?? '')}
        title={decodeHTML(product.title ?? '')}
        image={{
          url: product.image.url,
          altText: `Image of ${product.brand ?? ''} ${product.title}`,
        }}
        productUrl={product.url}
        price={{
          current: product.price.current,
          previous: product.price.previous,
          showSave: false,
        }}
        {...props}
        {...getProductProviderProps({ provider, product }, props)}
        promotionIdentifier={{
          displayName: 'Featured',
          colour: 'featured',
        }}
        data-criteo-view-beacon={product.onViewBeacon}
      />
    </div>
  );
}

export default InGridProductCard;
